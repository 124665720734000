<script>
import { ArrowRightIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Services from "@/components/services";
import Features from "@/components/features";
import Pricing from "@/components/pricing";
import Team from "@/components/team";
import Blog from "@/components/artist_showcase";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import DisturabtionCard from "@/components/disturabtioncard";
import ActionScroll from "@/components/actionScroll";
// import counter_section from "@/components/counter-section";


export default {
  components: {
    // StarIcon,
    ArrowRightIcon,
    Navbar,
    Services,
    Features,
    Pricing,
    Team,
    Blog,
    Contact,
    Footer,
    DisturabtionCard,
    ActionScroll,
  },
  data() {
    return {};
  },
};
</script>


<template>
  <div>

    <Navbar />
    <div v-scroll-spy>
     

        <!-- Hero Start -->
        <section class="hero-8 bg-center position-relative" id="home"
          style="height: 100vh ; overflow: hidden ; min-height: 900px;">
          <video autoplay muted loop class="background-video" src="@/assets/banner_v.mp4" type="video/mp4"></video>
          <div class="bg-overlay bg-dark"></div>
          <div class="container">
            <div class="row justify-content-center hero-content">
              <div class="col-lg-9">
                <div class="text-center" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                  <!-- <star-icon class="text-white sw-1_5 icon-lg icon-spin mb-4"></star-icon> -->
                  <h1 class="font-weight-semibold mb-4 text-white hero-8-title">
                    Stream Your Dream, Everywhere
                  </h1>
                  <p class="mb-5 text-white-70 w-lg-75 mx-auto">
                    Get your sound heard across the globel. Simplify your distribution process and connect with millions
                    of listeners on every major streaming service. Let your talent shine, starting today.
                  </p>
                  <router-link to="/sign-up">
                    <a href="" class="btn btn-light">Get Started
                      <arrow-right-icon class="icon-sm ms-1"></arrow-right-icon></a>
                  </router-link>
                </div>
                <DisturabtionCard />

              </div>

            </div>

          </div>

          <vue-particles class="particles-effect" color="#dedede" :moveSpeed="6" :particleSize="4"></vue-particles>
        </section>
        <!-- Hero End -->

        <!-- <counter_section />  -->

        <Features />
        <Services />
        <Pricing />
        <Blog />
        <Contact />
        <Footer />
      <ActionScroll />


    </div>
  </div>
</template>


<style scoped>
.particles-effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  /* Ensures the video stays behind other elements */
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Dark overlay for readability */
  z-index: 0;
}
</style>
