<template>
  <div class=" cx">

    <div class="row justify-content-center mb-4" data-aos="fade-up">
      <div class="col-lg-7 text-center">
        <h2 class="fw-bold">Artists & Labels Showcase</h2>
        <p class="text-muted">
          Dive into our curated lineup of extraordinary artists worldwide. Their unique sounds and remarkable stories
          await your discovery. Celebrate the magic of their music with us.
        </p>
      </div>
    </div>
    <div class="scrollable-list" data-aos="zoom-out-up">
      <div class="list-wrapper d-flex align-items-center">
        <div class=" " v-for="(item, index) in items" :key="index">
          <div class="card item-card">
            <img
              src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?uid=R138894707&ga=GA1.1.328607892.1733776042&semt=ais_hybrid"
              class="card-img-top" alt="artist" height="80%" style="object-fit: cover;" >
            <div class="text-center">
              <h1 class="fw-bold fs-5 pt-2 "> {{ item.artist_name }}</h1>
              <p class="fw-medium pb-2"> {{ item.artist_title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      items: [
        { "artist_name": "John Mayer", "artist_title": "Guitar Maestro", "profile": "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?uid=R138894707&ga=GA1.1.328607892.1733776042&semt=ais_hybrid" },
        { "artist_name": "Ariana Grande", "artist_title": "Pop Icon", "profile": "https://en.wikipedia.org/wiki/Ariana_Grande" },
        { "artist_name": "Drake", "artist_title": "Rap Visionary", "profile": "https://en.wikipedia.org/wiki/Drake_(musician)" },
        { "artist_name": "Taylor Swift", "artist_title": "Lyric Queen", "profile": "https://en.wikipedia.org/wiki/Taylor_Swift" },
        { "artist_name": "Ed Sheeran", "artist_title": "Melody Architect", "profile": "https://en.wikipedia.org/wiki/Ed_Sheeran" },
        { "artist_name": "Beyoncé", "artist_title": "Queen of R&B", "profile": "https://en.wikipedia.org/wiki/Beyonc%C3%A9" },
        { "artist_name": "Billie Eilish", "artist_title": "Alt-Pop Sensation", "profile": "https://en.wikipedia.org/wiki/Billie_Eilish" },
        { "artist_name": "Bruno Mars", "artist_title": "Funk Master", "profile": "https://en.wikipedia.org/wiki/Bruno_Mars" },
        { "artist_name": "The Weeknd", "artist_title": "R&B Innovator", "profile": "https://en.wikipedia.org/wiki/The_Weeknd" },
        { "artist_name": "Kanye West", "artist_title": "Hip-Hop Visionary", "profile": "https://en.wikipedia.org/wiki/Kanye_West" },
        { "artist_name": "Rihanna", "artist_title": "Fashion & Music Diva", "profile": "https://en.wikipedia.org/wiki/Rihanna" },
        { "artist_name": "Lady Gaga", "artist_title": "Pop Rebel", "profile": "https://en.wikipedia.org/wiki/Lady_Gaga" },
        { "artist_name": "Adele", "artist_title": "Voice of Emotion", "profile": "https://en.wikipedia.org/wiki/Adele" },
        { "artist_name": "Justin Bieber", "artist_title": "Pop Star", "profile": "https://en.wikipedia.org/wiki/Justin_Bieber" },
        { "artist_name": "Shawn Mendes", "artist_title": "Heartthrob Crooner", "profile": "https://en.wikipedia.org/wiki/Shawn_Mendes" },
        { "artist_name": "Dua Lipa", "artist_title": "Disco Revivalist", "profile": "https://en.wikipedia.org/wiki/Dua_Lipa" },
        { "artist_name": "Lizzo", "artist_title": "Empowerment Leader", "profile": "https://en.wikipedia.org/wiki/Lizzo" },
        { "artist_name": "Eminem", "artist_title": "Rap Legend", "profile": "https://en.wikipedia.org/wiki/Eminem" },
        { "artist_name": "Post Malone", "artist_title": "Genre Blender", "profile": "https://en.wikipedia.org/wiki/Post_Malone" },
        { "artist_name": "Olivia Rodrigo", "artist_title": "Teen Pop Sensation", "profile": "https://en.wikipedia.org/wiki/Olivia_Rodrigo" }
      ]



    };
  },
};
</script>

<style scoped>
.cx {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 10%;
}

/* Container for auto-scrolling and hiding scrollbar */
.scrollable-list {
  margin-top: 5%;

  overflow-x: auto;
  /* Enables horizontal scrolling */
  white-space: nowrap;
  position: relative;

  /* Adjust based on card height */

  scrollbar-width: none;
  /* Firefox hides scrollbar */
  -ms-overflow-style: none;
  /* IE and Edge hides scrollbar */
}

.scrollable-list::-webkit-scrollbar {
  display: none;
  /* Chrome and Safari hides scrollbar */
}

/* Wrapper for cards with animation */
.list-wrapper {
  display: flex;
  gap: 5px;
  animation: scroll-left 10s linear infinite;
  /* Auto-scroll animation */
}


/* Individual card styling */
.item-card {
  /* Prevent shrinking */
  width:320px;
  height: 400px;
  margin-bottom: 10%;
  margin-right: 40px;
  background: transparent;
  /* Slight transparency */
  backdrop-filter: blur(15px);
  /* Apply blur effect */
  box-shadow: 0 4px 15px rgba(46, 46, 46, 0.2);
  /* Smooth shadow for better aesthetics */

  border: 1px solid #ffffff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: border-color-change 3s infinite linear;
  /* Border animation */
}

/* Image inside the card */


/* Keyframes for border animation */
@keyframes border-color-change {
  0% {
    border-color: rgb(0, 0, 0);
    /* Red */
  }

  25% {
    border-color: rgb(255, 255, 255);
    /* Yellow */
  }

  50% {
    border-color: rgb(0, 0, 0);
    /* Green */
  }

  75% {
    border-color: rgb(255, 255, 255);
    /* Blue */
  }

  100% {
    border-color: rgb(0, 0, 0);
    /* Back to Red */
  }
}

/* Keyframes for auto-scroll */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .cx {
    width: 98%;
    margin: 0 auto;
    margin-bottom: 10%;
  }

}
</style>
