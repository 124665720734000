<template>
    <div>
        <!-- Scroll Up/Down Button -->
        <button v-if="showButton" @click="toggleScroll" class="scroll-button">
            <component :is="buttonText === 'Scroll Down' ? ArrowDownCircleIcon : ArrowUpCircleIcon"></component>
        </button>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from 'vue-feather-icons'
export default {
    methods: {
    },
    setup() {
        const showButton = ref(false);  // To control button visibility
        const lastScrollY = ref(0);  // To track last scroll position
        const buttonText = ref('Scroll Down');  // Text of the button

        // Method to scroll to the top of the page
        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        // Method to scroll to the bottom of the page
        const scrollToBottom = () => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        };

        // Method to toggle between scrolling up or down
        const toggleScroll = () => {
            if (buttonText.value === 'Scroll Down') {
                scrollToBottom();
                buttonText.value = 'Scroll Up';
            } else {
                scrollToTop();
                buttonText.value = 'Scroll Down';
            }
        };

        // Check the scroll position and update the button visibility and text
        const checkScrollPosition = () => {
            if (window.scrollY > 200) {
                showButton.value = true;

                // Check scroll direction
                if (window.scrollY > lastScrollY.value) {
                    // Scrolling down
                    if (buttonText.value !== 'Scroll Down') {
                        buttonText.value = 'Scroll Down';
                    }
                } else {
                    // Scrolling up
                    if (buttonText.value !== 'Scroll Up') {
                        buttonText.value = 'Scroll Up';
                    }
                }
            } else {
                showButton.value = false;
            }
            lastScrollY.value = window.scrollY; // Update last scroll position
        };

        // Setup scroll event listener when the component is mounted
        onMounted(() => {
            window.addEventListener('scroll', checkScrollPosition);
        });

        // Remove event listener before the component is destroyed
        onBeforeUnmount(() => {
            window.removeEventListener('scroll', checkScrollPosition);
        });

        return {
            showButton,
            buttonText,
            toggleScroll,
            ArrowDownCircleIcon,
            ArrowUpCircleIcon
        };
    },
};
</script>

<style scoped>
.scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 12px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    /* Initially hidden */
    transition: opacity 0.3s ease;
}

.scroll-button:focus {
    outline: none;
}

.scroll-button.show {
    display: block;
}
</style>