<script>
import { ArrowRightIcon } from "vue-feather-icons";

export default {
  methods: {
  },
  components: { ArrowRightIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- Features start -->
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row justify-content-center mb-5" data-aos="fade-up">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">Our Features</h2>
            <p class="text-muted">
              Share your music effortlessly with global audiences through an extensive network of platforms. Reach
              millions of listeners and expand your presence with seamless uploads. Our platform makes music
              distribution simple and efficient, empowering artists to focus on their creativity.
            </p>
          </div>
        </div>

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5" data-aos="fade-up">
            <h2 class="mb-4">Why do you distribute your music through our company?</h2>
            <p class="text-muted mb-5">
              After 10 long years of research and development, we are thrilled to announce the launch of our platform.
              We are passionate about empowering artists to share their talents with the world, and we have created a
              system that makes it easy for anyone to release their music.

              With just a few simple steps, you can upload your song, create a professional marketing campaign, and
              start reaching new fans. We also have a thriving community of artists and music lovers who are ready to
              support you on your journey.

              So what are you waiting for? Sign up today and start making your dreams a reality.
            </p>
            <a href="javascript: void(0);" class="btn btn-primary">Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon></a>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2" data-aos="fade-left">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img src="@/assets/f_02.jpg" alt="" class="img-fluid d-block ms-auto rounded shadow" />
              </div>
              <img src="@/assets/images/dot-img.png" alt="" class="dot-img-left" />
            </div>
          </div>
        </div>
        <!-- end row -->
        <div class="row align-items-center section mb-5">
          <div class="col-md-6" data-aos="fade-right">
            <div class="position-relative mb-md-0 mb-5">
              <div class="me-5 features-img">
                <img src="@/assets/f_03.jpg" alt="" class="img-fluid d-block ms-auto rounded shadow" />
              </div>
              <img src="@/assets/images/dot-img.png" alt="" class="dot-img-right" />
            </div>
          </div>
          <div class="col-md-5 ms-md-auto" data-aos="fade-up">
            <h2 class="mb-4">
              Distribute Your Music Globally
            </h2>
            <p class="text-muted mb-5">
              Release your music reach worldwide. Step out of the shadows. If your music deserves to be heard, it
              deserves a global audience. Distribute your music to online platforms and make waves. Showcase your work
              on leading platforms like Spotify, Apple Music, Amazon Music, and and many more
            </p>
            <a href="javascript: void(0);" class="btn btn-primary">Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon></a>
          </div>
        </div>
        <!-- end row -->

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5" data-aos="fade-up">
            <h2 class="mb-4">Own Your Music</h2>
            <p class="text-muted mb-5">
              Orginal Your Music. Empower yourself as the creator of your music. We stand by the artist’s freedom in
              crafting their work. Believe in yourself, as we believe in you. </p>
            <a href="javascript: void(0);" class="btn btn-primary">Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon></a>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2" data-aos="fade-left">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img src="@/assets/Own-Your-Music.jpg" alt="" class="img-fluid d-block ms-auto rounded shadow" />
              </div>
              <img src="@/assets/images/dot-img.png" alt="" class="dot-img-left" />
            </div>
          </div>
        </div>

        <!-- end row -->



      </div>
      <!-- end container -->
    </section>
    <!-- Features end -->


    <!-- Cta end -->
  </div>
</template>